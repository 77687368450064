import Icon from "@/components/base/Icon"
import { Box, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import FooterLogo from "../footer-logo"

const AccessDenied = () => {
    const { t } = useTranslation()
    return (
        <Box className="first-step" data-e2e="access-denied" data-testid="access-denied">
            <Stack gap={2} className="success-wrapper">
                <Stack textAlign={"center"} gap={2}>
                    <Typography variant="h2" sx={{ fontWeight: 500, fontSize: 24 }}>
                        {t("FEEDBACK.ACCESS_ERROR")}
                    </Typography>
                    <Icon name="access-denied" sx={{ width: 240, marginLeft: "auto", marginRight: "auto" }} />
                </Stack>
            </Stack>
            <FooterLogo />
        </Box>
    )
}

export default AccessDenied
