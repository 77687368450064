import ResellerStore, { INotificationSetter, INotificationStore } from "@/store/notification"

class NotificationService {
    getState = (): INotificationStore & INotificationSetter => {
        return ResellerStore.getState()
    }

    setState = (update: Partial<INotificationStore>): void => {
        const { update: updateState } = this.getState()
        updateState(update)
    }
}

export default new NotificationService()
