const ENV = import.meta.env

const env = {
    ENV: ENV.VITE_ENV,
    GW_BASE_URL: ENV.VITE_GW_BASE_URL,
    API_BASE_URL: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_APPBACK_API_PATH}`,
    API_DISPATCHR_URL: `${ENV.VITE_GW_BASE_URL}/${ENV.VITE_DISPATCHR_API_PATH}`,
    LANG: ENV.VITE_LANG,
    SENTRY_ENV: ENV.VITE_SENTRY_ENV,
    APP_VERSION: ENV.VITE_VERSION,
    MUIX_LICENSE: ENV.VITE_MUIX_API_KEY,
    APP_NAME: ENV.VITE_APP_NAME,
    WEBSOCKET_HANDSHAKE_PATH: ENV.VITE_WEBSOCKET_HANDSHAKE_PATH,

    SENTRY_DSN: ENV.VITE_SENTRY_DSN,
    SENTRY_TRACE_SAMPLE_RATE: Number(ENV.VITE_SENTRY_TRACE_SAMPLE_RATE),
}

export default env
