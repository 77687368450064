import FeedbackForm from "@/components/form/feedback-form"
import { FeedbackFormType } from "@/components/form/feedback-form/useFeedbackForm"
import { Box, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import FooterLogo from "../footer-logo"

const SecondStep = ({
    sendReview,
    isLoading,
}: {
    sendReview: (formValue: FeedbackFormType) => void
    isLoading: boolean
}) => {
    const { t } = useTranslation()
    return (
        <Box className="second-step" flexDirection={"column"} gap={2}>
            <Box>
                <Typography variant="h3" data-testid="step-two-title">
                    {t("FEEDBACK.STEP_TWO.TITLE")}
                </Typography>
                <Typography sx={{ textAlign: "center" }}>{t("FEEDBACK.STEP_TWO.SUB_TITLE")}</Typography>
            </Box>
            <Stack flexDirection={"column"} gap={2}>
                <FeedbackForm submit={sendReview} loading={isLoading} />
            </Stack>
            <FooterLogo />
        </Box>
    )
}

export default SecondStep
