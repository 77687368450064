import { CompanyApi } from "@/api"
import ResellerStore, { ICompanySetter, ICompanytore } from "@/store/company"
import { InfType } from "@/types/inf"
import Cookies from "js-cookie"

class CompanyService {
    getState = (): ICompanytore & ICompanySetter => ResellerStore.getState()

    setState = (update: Partial<ICompanytore>): void => {
        const { updateState } = this.getState()
        updateState(update)
    }

    getInfos = async (inf: InfType) => {
        const reseller = await CompanyApi.fetchCompanyInfos({
            company_id: inf.id,
            location_id: inf.location_id,
        })
        const feedback_metadata = {
            ...inf,
            ...{
                company_name: reseller?.company?.name || "",
                company_logo: reseller?.company?.logo || "",
            },
        }
        Cookies.set("feedback_metadata", JSON.stringify(feedback_metadata))

        return feedback_metadata
    }

    sendReview = async (body) => {
        return await CompanyApi.sendReview(body)
    }

    clearReseller = () => {
        Cookies.remove("companyName")
        Cookies.remove("companyLogo")
    }
}

export default new CompanyService()
