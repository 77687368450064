import Icon from "@/components/base/Icon"
import { Box, Paper, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import FooterLogo from "../footer-logo"

const FirstStep = ({ redirectToGoogle, openNextStep }) => {
    const { t } = useTranslation()
    return (
        <Box className="first-step">
            <Typography variant="h1" data-testid="step-one-title">
                {t("FEEDBACK.TITLE")}
            </Typography>
            <Stack flexDirection={"column"} gap={2}>
                <Typography variant="h3">{t("FEEDBACK.STEP_ONE.TITLE")}</Typography>
                <Stack flexDirection="row" gap={2}>
                    <Paper
                        elevation={2}
                        className="btn-review good"
                        onClick={redirectToGoogle}
                        data-testid="step-one-good"
                    >
                        <Icon name="feedbackCheck" />
                        <Icon name="successHand" />
                        <Typography className="text-review good">{t("FEEDBACK.STEP_ONE.SATISFIED")}</Typography>
                    </Paper>
                    <Paper elevation={2} className="btn-review bad" onClick={openNextStep} data-testid="step-one-bad">
                        <Icon name="feedbackCheck" />
                        <Icon name="warningHand" />
                        <Typography className="text-review bad">{t("FEEDBACK.STEP_ONE.NOT_SATISFIED")}</Typography>
                    </Paper>
                </Stack>
            </Stack>
            <FooterLogo />
        </Box>
    )
}
export default FirstStep
