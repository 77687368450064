import useStyles from "@/components/feedback/styles"
import { isValidURL } from "@/utils"
import { Box } from "@mui/material"

const FeedbackLogo = ({ logo, name }: { logo: string; name: string }) => {
    const { classes } = useStyles()
    return (
        isValidURL(logo) &&
        name &&
        logo && (
            <Box className={classes.feedbackLogo}>
                <img src={logo} alt={name} data-testid="company-logo" />
            </Box>
        )
    )
}

export default FeedbackLogo
