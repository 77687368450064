import { Button, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const CrashAppComponent = () => {
    const { t } = useTranslation()
    return (
        <Stack
            sx={{
                height: "100vh",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
            }}
        >
            <Stack sx={{ width: "344px", height: "154px" }} textAlign={"center"} gap={3}>
                <Stack gap={1}>
                    <Typography variant="h3" textAlign={"center"}>
                        {t("PAGE_ERRORS.CRASH_APP.TITLE")}
                    </Typography>
                    <Typography variant="body1" color="text.secondary">
                        {t("PAGE_ERRORS.CRASH_APP.SUBTITLE")}
                    </Typography>
                </Stack>
                <Button variant="contained" sx={{ width: "145px", m: "auto" }} href="/">
                    {t("PAGE_ERRORS.CRASH_APP.BUTTON_RELOAD")}
                </Button>
            </Stack>
        </Stack>
    )
}

export default CrashAppComponent
