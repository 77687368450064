import env from "@/config/env"
import { io } from "socket.io-client"

const socket = io(`${env.GW_BASE_URL}`, {
    path: `/${env.WEBSOCKET_HANDSHAKE_PATH}/socket.io`,
    transports: ["websocket"],
    query: {
        env: `${env.APP_NAME}-${env.ENV}`,
    },
})

export default socket
