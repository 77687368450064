import AccessDenied from "@/components/feedback/access-denied"
import FirstStep from "@/components/feedback/first-step"
import FeedbackLogo from "@/components/feedback/logo"
import SecondStep from "@/components/feedback/second-step"
import SendSuccess from "@/components/feedback/send-success"
import useStyles from "@/components/feedback/styles"
import useFeedback from "@/hooks/feedback/useFeedback"
import CompanyStore from "@/store/company"
import { Box, Stack } from "@mui/material"
import { useMemo } from "react"

interface FeedbackPageData {
    companyLogo: string
    companyName: string
    accessDenied: boolean
    step: number
    reviewSent: boolean
}

const FeedbackPage = ({ mockData }: { mockData?: FeedbackPageData }) => {
    const { classes } = useStyles()
    const { companyLogo, companyName, accessDenied, dislike, reviewUrl } = CompanyStore()
    const { step, reviewSent, isSending, openNextStep, redirectToGoogle, sendReview } = useFeedback()
    const pageData: FeedbackPageData = useMemo(() => {
        if (mockData) return mockData
        else
            return {
                companyLogo,
                companyName,
                accessDenied,
                step: dislike ? 1 : step,
                reviewSent,
            }
    }, [companyLogo, companyName, accessDenied, step, reviewSent, dislike, mockData])

    return (
        <Box className={`${classes.boxContainer} ${pageData?.step === 1 ? "split-page-container" : ""}`}>
            {!pageData?.accessDenied && (
                <>
                    <Stack className={`${classes.headerBloc} ${pageData?.step === 1 ? "split-page" : ""}`}>
                        {pageData?.companyLogo && (
                            <FeedbackLogo logo={pageData?.companyLogo} name={pageData?.companyName} />
                        )}
                    </Stack>
                    {!pageData?.reviewSent && (
                        <>
                            {pageData?.step === 0 && (reviewUrl || mockData) && (
                                <FirstStep openNextStep={openNextStep} redirectToGoogle={redirectToGoogle} />
                            )}
                            {pageData?.step === 1 && <SecondStep sendReview={sendReview} isLoading={isSending} />}
                        </>
                    )}
                    {pageData?.reviewSent && <SendSuccess />}
                </>
            )}
            {pageData?.accessDenied && <AccessDenied />}
        </Box>
    )
}

export default FeedbackPage
