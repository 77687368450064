import CrashAppComponent from "@/components/base/errors/crash-app/inde"
import FeedbackPage from "@/pages/feedback"
import { captureException } from "@sentry/react"
import { useEffect } from "react"
import { RouteObject, useRouteError } from "react-router-dom"
import ProvidersWithOutlet from "./providers-with-outlet"
// TODO: create middleware for routes
function SentryRouteErrorFallback() {
    const routeError = useRouteError()

    useEffect(() => {
        captureException(routeError, { level: "fatal" })
    }, [routeError])

    return <CrashAppComponent />
}
const RoutesList: RouteObject[] = [
    {
        path: "*",
        element: <ProvidersWithOutlet />,
        errorElement: <SentryRouteErrorFallback />,
        children: [
            {
                index: true,
                element: <FeedbackPage />,
            },
        ],
    },
]

export default RoutesList
