import Icon from "@/components/base/Icon"
import { Box, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"
import FooterLogo from "../footer-logo"

const SendSuccess = () => {
    const { t } = useTranslation()
    return (
        <Box className="first-step" data-e2e="success-review" data-testid="success-review">
            <Stack gap={2} className="success-wrapper">
                <Stack textAlign={"center"} gap={2}>
                    <Icon name="success" sx={{ width: 240, marginLeft: "auto", marginRight: "auto" }} />
                    <Typography variant="h2" sx={{ fontWeight: 500, fontSize: 24 }}>
                        {t("FEEDBACK.STEP_TWO.SUCCESS")}
                    </Typography>
                    <Typography component={"span"} sx={{ fontWeight: 400, color: "#68738D" }}>
                        {t("FEEDBACK.STEP_TWO.SUCCESS_MESSAGE")}
                    </Typography>
                </Stack>
            </Stack>
            <FooterLogo />
        </Box>
    )
}

export default SendSuccess
