import NotificationStore from "@/store/notification"
import { Box } from "@mui/material"
import classNames from "classnames"
import SnackbarError from "./snackbar-error"
import SnackbarNewVersion from "./snackbar-new-version"
import SnackbarSuccess from "./snackbar-success"
import useStyles from "./style"

const Notification = () => {
    const { classes } = useStyles()
    const { type, show } = NotificationStore()

    return (
        <Box className={classNames(classes.snackbarsWrapper, show && "shown")}>
            {type === "ERROR" && <SnackbarError />}
            {type === "SUCCESS" && <SnackbarSuccess />}
            {type === "UPDATE" && <SnackbarNewVersion />}
        </Box>
    )
}

export default Notification
