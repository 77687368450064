import Cookies from "js-cookie"
import { create } from "zustand"

export interface IUiStore {
    title: string
    favicon: string
    preferredLanguage: string
    showMenu: boolean
}

export interface IUiSetter {
    updateState: (update: Partial<IUiStore>) => void
    setShowMenu: (value: boolean) => void
}

const initalState = {
    title: "",
    favicon: Cookies.get("resellerFavicon"),
    preferredLanguage: "",
    showMenu: false,
}

const UiStore = create<IUiStore & IUiSetter>((set) => ({
    ...initalState,
    updateState: (update) => set(() => ({ ...update })),
    setShowMenu: (showMenu) => set(() => ({ showMenu })),
}))

export default UiStore
