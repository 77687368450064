import env from "@/config/env"
import * as Sentry from "@sentry/react"
import React from "react"
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom"
class SentryService {
    init() {
        const release = `feedback_${env.ENV}@${env.APP_VERSION}`
        Sentry.init({
            dsn: env.SENTRY_DSN,
            release,
            environment: env.ENV,
            integrations: [
                // See docs for support of different versions of variation of react router
                // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
                Sentry.reactRouterV6BrowserTracingIntegration({
                    useEffect: React.useEffect,
                    useLocation,
                    useNavigationType,
                    createRoutesFromChildren,
                    matchRoutes,
                }),
                Sentry.dedupeIntegration(),
                Sentry.replayIntegration(),
                Sentry.browserTracingIntegration(),
                // captureConsoleIntegration(),
            ],
            // Performance Monitoring
            tracesSampleRate: env.SENTRY_TRACE_SAMPLE_RATE, //  Capture 100% of the transactions
            attachStacktrace: true,
            enabled: env.ENV !== "local",
        })
    }
}

export default new SentryService()
