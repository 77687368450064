import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()((theme) => ({
    drawer: { zIndex: 1 },
    containerSuccess: {
        padding: 0,
        width: "100%",
        boxShadow: "none",
        gap: 16,
        backgroundColor: theme.palette.background.default,
        "@media (min-width: 768px)": {
            height: "100%",
            width: "calc(100% - 251px)",
        },
        "& .children-wrapper": {
            padding: 8,
            display: "flex",
            flexDirection: "column",
            gap: 16,
            margin: "auto",
            "& .div-img": {
                margin: "auto",
            },
            "@media (min-width: 768px)": {
                padding: 24,
            },
            a: {
                textDecoration: "none",
            },
        },
    },
    boxContainer: {
        display: "flex",
        gap: 24,
        flexDirection: "column",
        padding: "0 24px",
        minHeight: "100vh",
        justifyContent: "center",
        "& .btn-mobile": {
            "@media (max-width: 768px)": {
                width: "100%",
            },
        },
        "& h1": {
            fontSize: 34,
            textAlign: "center",
        },
        "& h3": {
            color: theme.palette.action.active,
            fontSize: 20,
            lineHeight: "32px",
            textAlign: "center",
        },
        "& .first-step": {
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: 24,
            maxWidth: 345,
            marginLeft: "auto",
            marginRight: "auto",
            // minHeight: "calc(100vh - 148px)",
            "& .btn-review": {
                maxWidth: 160,
                width: "100%",
                maxHeight: 160,
                display: "flex",
                alignItems: "center",
                flexDirection: "column",
                position: "relative",
                textAlign: "center",
                cursor: "pointer",
                "& .feedbackCheck": {
                    position: "absolute",
                    top: 8.35,
                    right: 7.5,
                },
                "& .warningHand, & .successHand": {
                    marginTop: 25.52,
                },
            },
            "& .good": {
                color: "#03CC6C",
                "&:hover, &:focus": {
                    borderColor: "#03CC6C",
                    "& .rect": {
                        fill: "#03CC6C",
                        fillOpacity: 1,
                    },
                },
            },
            "& .bad": {
                color: "#FFC431",
                "&:hover, &:focus": {
                    borderColor: "#FFC431",
                    "& .rect": {
                        fill: "#FFC431",
                        fillOpacity: 1,
                    },
                },
            },
        },
        "& .second-step": {
            maxWidth: 405,
            marginLeft: "auto",
            marginRight: "auto",
            display: "flex",
            // minHeight: "calc(100vh - 148px)",
            justifyContent: "center",
            "& h3": {
                color: theme.palette.secondary.main,
            },
            "& p": {
                color: "#68738D",
                fontSize: 16,
                marginTop: 8,
            },
            "& .MuiFormControl-root": {
                width: "100%",
            },
            "& textarea:first-of-type": {
                minHeight: "158px",
            },
            "& .feedback-form-stack": {
                "@media (max-width: 768px)": {
                    flexDirection: "column",
                },
            },
        },
        "&.split-page-container": {
            paddingBottom: 24,
            // justifyContent: "flex-start",
            height: "auto",
        },
    },

    headerBloc: {
        marginLeft: "auto",
        marginRight: "auto",
        display: "flex",
        flexDirection: "column",
        alignContent: "center",
        alignItems: "center",
        maxHeight: 124,
        marginTop: 24,
        "& .success-wrapper": {
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
            img: {
                marginLeft: "auto",
                marginRight: "auto",
                maxWidth: 240,
            },
        },
    },
    feedbackLogo: {
        "& img": {
            width: 100,
        },
    },
    footerLogo: {
        width: "100%",
        display: "flex",
        justifyContent: "center",
        "& .powered, & .version": {
            color: "#68738DA3",
            textAlign: "center",
            fontSize: "12px",
            lineHeight: "19.92px",
        },
    },
}))

export default useStyles
