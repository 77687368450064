import { makeStyles } from "tss-react/mui"

const useStyles = makeStyles()(() => ({
    snackbarsWrapper: {
        position: "fixed",
        bottom: 16,
        height: "auto",
        width: "100%",
        right: 0,
        "&.shown": {
            zIndex: 2,
        },
        "@media (min-width: 768px)": {
            width: "100%",
            right: 0,
            bottom: 24,
            left: "initial",
            transform: "none",
        },
        "& .MuiSnackbar-root": {
            position: "absolute",
            width: "100%",
            maxWidth: 655,
            bottom: 0,
            right: 0,
            "@media (min-width: 768px)": {
                left: "50%",
                transform: "translateX(-50%)",
                top: "-42px",
            },
            "@media (max-width: 768px)": {
                maxWidth: "100%",
                left: 0,
                right: 0,
                width: "calc(100% - 24px)",
                display: "block",
                marginLeft: "auto",
                marginRight: "auto",
                position: "static",
                transform: "none",
            },
            "& .MuiPaper-root": {
                padding: 16,
                width: "100%",
                justifyContent: "space-between",
                color: "white",
                "@media (min-width: 768px)": {
                    transform: "translateX(-50%)",
                },
                "& .MuiAlert-message": {
                    fontSize: 16,
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                    width: "100%",
                    padding: 0,
                },
                "& .MuiAlert-icon": {
                    color: "white",
                    alignItems: "center",
                    padding: 0,
                },
                "& .MuiAlert-action": {
                    padding: 0,
                    "& button": {
                        padding: 0,
                    },
                },
            },
        },
    },
}))

export default useStyles
