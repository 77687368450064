import env from "@/config/env"
import { ErrorHandlerRequestService } from "@/services"
import axios, { AxiosInstance, AxiosRequestConfig } from "axios"

class ApiService {
    client: AxiosInstance
    constructor() {
        this.client = axios.create({
            baseURL: `${env.API_BASE_URL}`,
        })
    }

    injectToken = () => this.client

    post = async (url: string, body: any = {}, config: AxiosRequestConfig = {}) => {
        return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
            const response = await this.injectToken().post(url, body, config)
            return response?.data
        })
    }

    postFile = async (url: string, file: any, body: any = {}, config: AxiosRequestConfig = {}) => {
        const formData = new FormData()
        formData.append(body.name, body.value)
        formData.append("file", file)
        return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
            const response = await this.injectToken().post(url, formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
            })
            return response?.data
        })
    }

    postFiles = async (url: string, files: any, body: any = {}, config: AxiosRequestConfig = {}) => {
        const formData = new FormData()
        formData.append(body.name, body.value)
        files.map((file) => {
            formData.append("file[]", file)
        })
        return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
            const response = await this.injectToken().post(url, formData, {
                ...config,
                headers: {
                    "Content-Type": "multipart/form-data",
                },
                data: formData,
            })
            return response?.data
        })
    }

    get = async (url: string, params: any = {}, config: AxiosRequestConfig = {}) => {
        return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
            const response = await this.injectToken().get(url, { params, ...config })
            return response?.data
        })
    }

    put = async (url: string, body: any = {}, config: AxiosRequestConfig = {}) => {
        return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
            const response = await this.injectToken().put(url, body, config)
            return response?.data
        })
    }

    delete = async (url: string, config: AxiosRequestConfig = {}) => {
        return ErrorHandlerRequestService.asyncHandlerRequestError(async () => {
            const response = await this.injectToken().delete(url, config)
            return response?.data
        })
    }
}

export default new ApiService()
