import Notification from "@/components/notification"
import { CompanyService } from "@/services"
import LangService from "@/services/lang"
import CompanyStore from "@/store/company"
import NotificationStore from "@/store/notification"
import UiStore from "@/store/ui"
import { InfType } from "@/types/inf"
import { decode64AndParse, removeFeedbackMetadata } from "@/utils"
import Cookies from "js-cookie"
import React, { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { Outlet, useSearchParams } from "react-router-dom"

const ProvidersWithOutlet = () => {
    const { updateState: updateCompanyState } = CompanyStore()
    const { favicon, preferredLanguage, updateState } = UiStore()
    const { i18n, ready } = useTranslation()
    const { update: updateNotifState } = NotificationStore()
    const [searchParams] = useSearchParams()
    useEffect(() => {
        if (favicon) {
            const link = document.querySelector("link#favicon")
            if (link) link.setAttribute("href", favicon + "?v=2")
        }
    }, [favicon])

    useEffect(() => {
        function handleLanguageChange() {
            const language = LangService.preloadLanguage(navigator.languages)
            updateState({ preferredLanguage: language })
        }
        window.addEventListener("languagechange", handleLanguageChange)
        return () => {
            window.removeEventListener("languagechange", handleLanguageChange)
        }
    }, [updateState])

    useEffect(() => {
        const language = LangService.preloadLanguage(navigator.languages)
        document.documentElement.lang = language
        Cookies.set("lang", language)
        updateState({ preferredLanguage: language })
    }, [updateState])

    const getCompany = useCallback(async (inf: InfType) => {
        const reseller = await CompanyService.getInfos(inf)
        CompanyService.setState({
            companyName: reseller?.company_name,
            companyLogo: reseller?.company_logo,
            locationId: reseller?.location_id,
            reviewUrl: reseller?.review_url,
            companyId: reseller?.id,
            isFetching: false,
            accessDenied: false,
        })
    }, [])

    const clearAll = useCallback(
        (query) => {
            const queryOld = Cookies.get("feedback_metadata") ?? "{}"
            const infos = JSON.parse(queryOld ?? "") as InfType
            const decodedInfo = query ? decode64AndParse(query ?? "") : infos
            const infosOld = JSON.parse(queryOld ?? "") as InfType
            if (decodedInfo?.id || queryOld != "{}") {
                removeFeedbackMetadata()
                if (decodedInfo?.dislike === "1" || infosOld?.dislike === "1") {
                    updateCompanyState({ dislike: true })
                }
                updateCompanyState({ companyFetched: true })
                getCompany(decodedInfo)
                if (query) {
                    window.history.pushState({}, "", "/")
                }
            } else {
                removeFeedbackMetadata()
                updateCompanyState({
                    companyFetched: false,
                    isFetching: false,
                    accessDenied: true,
                })
            }
        },
        [getCompany, updateCompanyState]
    )

    useEffect(() => {
        const query = searchParams.get("inf")
        CompanyService.setState({
            companyName: "",
            companyLogo: "",
            locationId: "",
            reviewUrl: "",
            companyId: "",
            isFetching: false,
            accessDenied: false,
        })
        clearAll(query)
        updateNotifState({ show: false })
    }, [searchParams, clearAll, updateNotifState])

    useEffect(() => {
        i18n.changeLanguage(preferredLanguage)
    }, [preferredLanguage, i18n])

    return (
        <React.Fragment>
            {ready && (
                <>
                    <Notification />
                    <Outlet />
                </>
            )}
        </React.Fragment>
    )
}

export default ProvidersWithOutlet
