import logoDilypse from "@/assets/images/logo-dilypse.png"
import useStyles from "@/components/feedback/styles"
import env from "@/config/env"
import { Box, Stack, Typography } from "@mui/material"
import { useTranslation } from "react-i18next"

const FooterLogo = () => {
    const { t } = useTranslation()
    const { classes } = useStyles()
    return (
        <Box className={classes.footerLogo}>
            <Stack flexDirection={"column"} gap={"8px"}>
                <Typography className="powered">{t("FEEDBACK.POWERED_BY")}</Typography>
                <img src={logoDilypse} alt="Dilypse" />
                <Typography className="version">v{env.APP_VERSION}</Typography>
            </Stack>
        </Box>
    )
}

export default FooterLogo
